/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "./layout.css"
import SEO from "./seo"

const Layout = ({ children, lang }) => (
  <>
    <SEO lang={lang} />
    {children}
  </>
)

Layout.defaultProps = {
  lang: `en`,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.string,
}

export default Layout
