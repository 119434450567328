/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const isEnglish = (lang) => {
  return !lang.startsWith('zh')
}

const metaLocaleAlternates = (currentLang) => {
  //console.log('currentLang:', currentLang)
  let l;
  if (currentLang[2] === '-') {
    l = currentLang.slice(0, 2) + '_' + currentLang.slice(3)
  } else {
    l = currentLang;
  }
  const otherLang = isEnglish(l) ? 'zh_CN' : 'en_US';
  return [{
    property: 'og:locale',
    content: l,
  }, {
    property: 'og:locale:alternate',
    content: otherLang,
  },]
}

function SEO({ lang }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            cnTitle
            cnDescription
            author
            image
            keywords
          }
        }
      }
    `
  )

  let description = site.siteMetadata.cnDescription,
      title = site.siteMetadata.cnTitle,
      author = site.siteMetadata.author,
      image = site.siteMetadata.image,
      keywords = site.siteMetadata.keywords;
  if (isEnglish(lang)) {
    description = site.siteMetadata.description;
    title = site.siteMetadata.title;
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title}
      meta={[
        {
          property: 'og:url',
          content: 'https://supernode.thundercore.com',
        },
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: image,
        },
        { // TODO: don't hard code images/super-node-open-graph.jpg size
          // https://developers.facebook.com/docs/sharing/best-practices/#images
          property: 'og:image:width',
          content: 1200,
        },
        { // TODO: don't hard code images/super-node-open-graph.jpg size
          property: 'og:image:height',
          content: 630,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `keywords`,
          content: keywords,
        }
      ].concat(metaLocaleAlternates(lang))}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
}

SEO.propTypes = {
  lang: PropTypes.string,
}

export default SEO
